<template>
  <v-container fluid>
    <v-data-table
      :items="teams"
      :headers="[
        { text: 'Verein', value: 'club.name', sortable: false },
        { text: 'Turner', value: 'athletes', sortable: false },
        { text: 'löschen', value: 'deleted', sortable: false },
        { text: 'Gruppe', value: 'group', sortable: false, align: 'center' },
        { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' },
        ...disc.map(d => ({ text: d.shortName, value: d._id, sortable: false }))
      ]"
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
    >
      <template #item="{item}">
        <tr :class="{deleted: item.deleted}">
          <td>{{ item.club.name }}</td>
          <td style="white-space:nowrap;">
            <template v-for="(a,i) in item.athletes">
              <br v-if="i > 0" :key="i" />
              {{ a | person }}
            </template>
          </td>
          <td><v-btn v-if="item.deleted" fab small @click.stop="del(item)"><v-icon>far fa-trash-alt</v-icon></v-btn></td>
          <td style="text-align: center;">
            <base-edit-dialog
              :value="item.group"
              :id="`${item.teamId}`"
              label="Gruppe"
              @input="({ value, id }) => setgroup(parseInt(id), parseInt(value))"
            />
          </td>
          <td style="text-align: center;">
            <base-edit-dialog
              :value="item.order"
              :id="`${item.teamId}`"
              label="Reihenfolge"
              @input="({ value, id }) => setorder(parseInt(id), parseInt(value))"
            />
          </td>
          <td v-for="d in disc" :key="d._id">
            <v-icon v-if="item._startdiscipline === d._id">far fa-check</v-icon>
            <v-btn
              v-else
              text
              fab
              x-small
              @click="setstartdiscipline(item.teamId, d._id)"
            >
              <v-icon color="grey lighten-2">far fa-xmark</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'riegen',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    teams () {
      return this.e.teams.slice().sort((a, b) => {
        if (!a.group && b.group) return 1
        if (a.group && !b.group) return -1
        if (a.group !== b.group) return a.group < b.group ? -1 : 1
        if (!a.order && b.order) return 1
        if (a.order && !b.order) return -1
        if (a.order && b.order) return a.order < b.order ? -1 : 1
        return a.club.name < b.club.name ? -1 : 1
      }).map(t => ({
        ...t,
        teamId: parseInt(t.identifiers?.find(id => id.name === 'gymnet.teamId')?.value)
      }))
    },
    disc () {
      return (this.df?.map(d => ({
        ...d,
        __typename: undefined,
        active: !!this.e?.disciplines?.find(ed => ed._discipline === d._id),
        order: this.e?.disciplines?.find(ed => ed._discipline === d._id)?.order
      })) || [])
        .filter(d => d.active)
        .sort((a, b) => {
          return a.order < b.order ? -1 : 1
        })
    }
  },

  methods: {
    setgroup (teamId, group) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $teamId: Int!, $group: Int!) {
            StbLtf2024TcTeamGroupUpdate(id: $id, teamId: $teamId, group: $group) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          teamId,
          group
        }
      })
    },
    setorder (teamId, order) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $teamId: Int!, $order: Int!) {
            StbLtf2024TcTeamOrderUpdate(id: $id, teamId: $teamId, order: $order) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          teamId,
          order
        }
      })
    },
    setstartdiscipline (teamId, did) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $teamId: Int!, $did: UUID!) {
            StbLtf2024TcTeamStartdisciplineUpdate(id: $id, teamId: $teamId, startdiscipline: $did) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          teamId,
          did
        }
      })
    },
    del (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $teamId: Int!) {
            StbLtf2024TcTeamDelete(id: $id, teamId: $teamId) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          teamId: item.teamId
        }
      })
    }
  }
}
</script>

<style scoped>
tr.deleted {
  background-color: rgba(255, 0, 0, 0.3);
  text-decoration: line-through;
}
</style>
